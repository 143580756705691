import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdMenu } from "react-icons/md";

import "../styles/Home.css";
import { useLocation, useNavigate } from "react-router";
import DesktopTopNavbar from "../components/DesktopTopNavbar";
import Togglebutton from "../components/Togglebutton";
import Dropdown from "../components/Dropdown";
import AudioRecorder from "../components/AudioRecorder";
import { isEmpty } from "../functions/isEmpty";

export default function Home({
  isMobile,
  apiURL,
  authToken,
  sidebarOpen,
  setSidebarOpen,
  stimmeaufgenommen,
  setStimmeaufgenommen,
  companies,
  localCompanyData,
  setLocalCompanyData,
  destroySession,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [recordStreams, setRecordStreams] = useState([]);

  const navigateToMeet = () => {
    navigate("/meet");
  };
  const navigateToCreate = () => {
    navigate("/create");
  };

  const navigateToCharacter = () => {
    navigate("/meet/1");
  };

  useEffect(() => {
    if (location !== "/create/gesichtscannen/stimmeaufnehmen") {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    }

    return () => {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    };
  }, [location]);

  return (
    <>
      {isMobile ? (
        <div className="home_mobile">
          Mobile Ansicht noch nicht programmiert. Bitte auf großem Bildschirm
          öffnen.
          <br />
          -Masih
        </div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            authToken={authToken}
            destroySession={destroySession}
          />
          <div className="home_split" onClick={() => setSidebarOpen(false)}>
            {location == "/" && (
              <div className="home_split_left">
                <div className="home_split_left_title">BE UNFORGETTABLE</div>
                <div className="home_split_left_text">
                  Hier bist du. Hier bleibst du. Dies ist dein Platz.
                  <br />
                  <br />
                  Hier kannst du dich, deine Erinnerungen, dein Denken, dein
                  Leben speichern.
                  <br />
                  <br />
                  Und so für deine Hinterbliebenen oder andere Interessierte auf
                  ewig weiter existieren.
                </div>
                <div className="home_split_left_form">
                  <div className="home_split_left_form_profile">
                    <div className="home_split_left_form_profile_col">
                      Erschaffe dich für die Ewigkeit
                      <Togglebutton
                        active={false}
                        text={"Gestalten"}
                        clickEvent={navigateToCreate}
                      />
                    </div>
                    <div className="home_split_left_form_profile_col">
                      Triff, wen immer du möchtest
                      <Togglebutton
                        active={false}
                        text={"Treffen"}
                        clickEvent={navigateToMeet}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {location == "/create" && (
              <div className="home_split_left">
                <div className="home_split_left_title">Erschaffe dich</div>
                <div className="home_split_left_text">
                  Bleibe so in Erinnerung, wie du möchtest, dass man sich an
                  dich erinnert.
                  <br />
                  <br />
                  Folge den einzelnen Schritten und gestalte dein
                  unvergängliches digitales Alter Ego.
                </div>
                <div className="home_split_left_form">
                  <Dropdown
                    title={"Avatar generieren"}
                    type={"avatargenerieren"}
                  />
                </div>
              </div>
            )}
            {location == "/create/stimmeaufnehmen" && (
              <>
                <div className="home_split_left">
                  <div className="home_split_left_title">Stimme aufnehmen</div>
                  <div className="home_split_left_text">
                    Der Raum, in dem du deine Stimme aufnimmst, sollte möglichst
                    ruhig sein. Kein Verkehrslärm, kein brummender Kühlschrank,
                    keine Hintergrundmusik, kein störender Mitbewohner sollte zu
                    hören sein.
                    <br />
                    <br />
                    <br />
                    Bitte lies den Tet oben dreimal vor (laut und deutlich und
                    so natürlich, wie du mit Freunden sprichst)
                  </div>
                  <div className="home_split_left_recordsection">
                    <AudioRecorder
                      stimmeaufgenommen={stimmeaufgenommen}
                      setStimmeaufgenommen={setStimmeaufgenommen}
                      recordStreams={recordStreams}
                      setRecordStreams={setRecordStreams}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                  </div>
                </div>
              </>
            )}
            {location == "/meet" && (
              <>
                <div className="home_split_left">
                  <div className="home_split_left_title">
                    Wen möchtest du treffen?
                  </div>
                  {/*
                         <div className="home_split_left_text">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                    sea takimata sanctus est Lorem ipsum dolor sit amet.
                  </div>
                  */}

                  <div className="home_split_left_form">
                    {/*
                               <div className="home_split_left_form_meet_title">
                        Wen möchtest du treffen?
                      </div>
                    */}
                    <div className="home_split_left_form_meet">
                      <div className="home_split_left_form_meet_inputbox">
                        <div className="home_split_left_form_meet_inputbox_text">
                          Vorname und Nachname
                        </div>
                        <input
                          className="home_split_left_form_meet_inputbox_input"
                          type="text"
                        />
                      </div>
                      <div className="home_split_left_form_meet_inputbox">
                        <div className="home_split_left_form_meet_inputbox_text">
                          Geburtsdatum (TTMMJJJ)
                        </div>
                        <input
                          className="home_split_left_form_meet_inputbox_input"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="home_split_left_form_nextbtn">
                      {/*
                           <Togglebutton
                        active={false}
                        text={"Jetzt treffen"}
                        clickEvent={navigateToCharacter}
                      />
                      
                      */}
                    </div>
                  </div>
                </div>
                <div className="home_split_right">
                  <div className="home_split_right_cards">
                    {!isEmpty(companies) &&
                      companies.map((company) => (
                        <div
                          className="home_split_right_cards_card"
                          onClick={() => navigate("/meet/" + company.id)}
                          key={company.id}>
                          <img
                            src={
                              !isEmpty(company.photosFace) &&
                              JSON.parse(company.photosFace)[0]
                            }
                            className="home_split_right_cards_card_img"
                          />
                          <div className="home_split_right_cards_card_overlay">
                            <div className="home_split_right_cards_card_overlay_text">
                              {company.firstname}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            {(location == "/" ||
              location == "/create" ||
              location == "/create/stimmeaufnehmen") && (
              <div className="home_split_right">
                <video
                  controls={false}
                  preload="metadata"
                  className={"home_split_right_background"}
                  id={"energiekopf"}
                  autoPlay={true}
                  playsInline={true}
                  muted={true}
                  loop={true}>
                  <source
                    src={
                      "https://www.developercloud.letsfluence.com/api/images/placeholder/Energiekopf_small.mp4" +
                      "#t=0.01"
                    }
                    type={`video/mp4`}
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

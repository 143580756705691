import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdFitnessCenter,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdMonetizationOn,
  MdOutlineCampaign,
  MdOutlineMonetizationOn,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPets,
  MdPlayCircleFilled,
  MdPlayCircleOutline,
  MdRestaurant,
  MdSearch,
  MdShield,
  MdShoppingCart,
  MdSports,
  MdSportsMma,
} from "react-icons/md";
import "../styles/DesktopTopNavbar.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../contexts/LanguageContext";
import { isEmpty } from "../functions/isEmpty";

export default function DesktopTopNavbar({
  isMobile,

  authToken,
  subPage,
  companyData,
  sidebarOpen,
  setSidebarOpen,
  destroySession,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();

  const returnHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="DesktopTopNavbar">
        <div className="DesktopTopNavbar_left">
          <img
            src="/assets/logo.svg"
            className="DesktopTopNavbar_left_logo"
            onClick={() => returnHome()}
          />
        </div>
        <div className="DesktopTopNavbar_right">
          <div
            className="DesktopTopNavbarright_menubtn"
            onClick={() => setSidebarOpen(true)}>
            <div className="DesktopTopNavbarright_menubtn_row"></div>
            <div className="DesktopTopNavbarright_menubtn_row"></div>
            <div className="DesktopTopNavbarright_menubtn_row"></div>
          </div>
        </div>
        <div
          id="sidebar"
          className={
            sidebarOpen
              ? "DesktopTopNavbar_Sidebar"
              : "DesktopTopNavbar_Sidebar d-none"
          }>
          <div className="DesktopTopNavbar_Sidebar_toprow">
            <div className="DesktopTopNavbar_Sidebar_toprow_left"></div>
            <div
              className="DesktopTopNavbar_Sidebar_toprow_closebtn"
              onClick={() => setSidebarOpen(false)}>
              <div className="DesktopTopNavbar_Sidebar_toprow_closebtn_rowleft"></div>
              <div className="DesktopTopNavbar_Sidebar_toprow_closebtn_rowright"></div>
            </div>
          </div>
          <div className="DesktopTopNavbar_Sidebar_headline">Gestalten</div>
          <div
            className="DesktopTopNavbar_Sidebar_subheadline"
            onClick={() => navigate("/create")}>
            Erschaffe dich
          </div>
          <div
            className="DesktopTopNavbar_Sidebar_subheadline"
            onClick={() => navigate("/create/characterdefinieren")}>
            Charakter definieren
          </div>
          <div
            className="DesktopTopNavbar_Sidebar_subheadline"
            onClick={() => navigate("/create/lebenkuratieren")}>
            Dein Leben in Bildern
          </div>
          {!isEmpty(authToken) ? (
            <div
              className="DesktopTopNavbar_Sidebar_subheadline"
              onClick={() => destroySession()}>
              Logout
            </div>
          ) : (
            <div
              className="DesktopTopNavbar_Sidebar_subheadline"
              onClick={() => navigate("/login")}>
              Profil erstellen/verwalten
            </div>
          )}

          <div className="DesktopTopNavbar_Sidebar_headline">Treffen</div>

          <div
            className="DesktopTopNavbar_Sidebar_subheadline"
            onClick={() => navigate("/meet")}>
            Wen möchtest du treffen?
          </div>
        </div>
      </div>
    </>
  );
}

const ArrowBack = styled(MdArrowBack)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-right: 5px;
`;

import { useState, useEffect } from "react";

const useScreenType = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    const updateScreenType = () => {
      let windowWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      setMobile(windowWidth < 700);
    };

    // Initial check
    updateScreenType();

    // Event listener for window resize
    window.addEventListener("resize", updateScreenType);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateScreenType);
    };
  }, []);

  return isMobile;
};

export default useScreenType;

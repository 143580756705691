import React, { useState, useRef, useEffect } from "react";
import { isEmpty } from "../functions/isEmpty";
import "../styles/Home.css";
import "../styles/AudioRecorder.css";
import Togglebutton from "./Togglebutton";
import { useNavigate } from "react-router";

const AudioRecorder = ({
  stimmeaufgenommen,
  setStimmeaufgenommen,
  recordStreams,
  setRecordStreams,
  localCompanyData,
  setLocalCompanyData,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioDuration, setAudioDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(1); // Volume ranges from 0.0 to 1.0
  const [isPlaying, setIsPlaying] = useState(false); // Track playback state
  const [microphoneAccess, setMicrophoneAccess] = useState(null); // Track microphone access
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);

  let navigate = useNavigate();

  const requestMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicrophoneAccess(true);
      console.log("Microphone access granted", stream);
      let helpArr = [...recordStreams];
      helpArr.push(stream);
      setRecordStreams(helpArr);

      setTimeout(() => {
        stream.getTracks().forEach((track) => track.stop());
      }, 1000);
    } catch (err) {
      if (
        err.name === "NotAllowedError" ||
        err.name === "PermissionDeniedError"
      ) {
        alert(
          "Microphone access is denied. Please enable access to use the recorder."
        );
      } else {
        console.error("Error accessing microphone", err);
      }
      setMicrophoneAccess(false);
    }
  };

  const handleStartStopRecording = async () => {
    if (isRecording) {
      // Stop recording
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    } else {
      // Start recording
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        let helpArr = [...recordStreams];
        helpArr.push(stream);
        setRecordStreams(helpArr);
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/mp3",
          });

          const audioUrl = URL.createObjectURL(audioBlob);
          setAudioURL(audioUrl);
          audioChunksRef.current = []; // Clear the chunks

          const file = new File([audioBlob], "recording.mp3", {
            type: "audio/mp3",
          });

          const localURL = URL.createObjectURL(file);
          const filetype = file.type;

          let audioObject = {
            localURL: localURL,
            files: file,
            filetype: filetype,
          };

          setStimmeaufgenommen([audioObject]);
        };

        mediaRecorder.start();
        setIsRecording(true);
      } catch (err) {
        console.error("Error accessing microphone", err);
        setMicrophoneAccess(false);
      }
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused || audioRef.current.ended) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleStop = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setCurrentTime(0);
    }
  };

  const handleVolumeChange = (newVolume) => {
    if (newVolume == 0) {
      audioRef.current.muted = true;
    } else {
      audioRef.current.muted = false;
    }
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };

  const handleDelete = () => {
    setAudioURL("");
    setAudioDuration(0);
    setCurrentTime(0);
    setStimmeaufgenommen([]);
  };

  const incrementTime = () => {
    if (audioRef.current) {
      let newTime = audioRef.current.currentTime + 1;
      if (newTime > audioDuration) newTime = audioDuration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const decrementTime = () => {
    if (audioRef.current) {
      let newTime = audioRef.current.currentTime - 1;
      if (newTime < 0) newTime = 0;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  useEffect(() => {
    requestMicrophoneAccess();
  }, []);

  useEffect(() => {
    if (!isEmpty(localCompanyData.soundsVoice)) {
      const firstAudio = localCompanyData.soundsVoice[0];
      setAudioURL(firstAudio);
    }
  }, [localCompanyData]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onloadedmetadata = () => {
        setAudioDuration(audioRef.current.duration);
      };

      audioRef.current.ontimeupdate = () => {
        setCurrentTime(audioRef.current.currentTime);
      };
      audioRef.current.onended = () => {
        setIsPlaying(false);
      };
    }
  }, [audioURL]);

  const saveAndProceed = () => {
    navigate("/create/characterdefinieren");
  };

  return (
    <div className="audiorecorder">
      <div className="audiorecorder_text">
        <div className="audiorecorder_text_top"></div>
        <div className="audiorecorder_text_bottom"></div>
      </div>
      <div className="audiorecorder_topbar">
        <div className="progress-bar-container">
          <img
            src="https://www.developercloud.letsfluence.com/api/images/placeholder/tonspur_transparent.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
            }}
          />
          <div
            className="progress-bar"
            style={{
              width:
                !isEmpty(audioRef.current) &&
                (currentTime / audioRef.current.duration).toFixed(2) * 100 +
                  "%",
            }}></div>
          <div
            className="progress-bar-border"
            style={{
              width: "3px",
              position: "relative",
              borderRadius: "999px",
              height: "100%",
              marginTop: "-113.92px",
              backgroundColor: "#ffffff",
              transition: "width 0.1s linear",

              zIndex: "99999999",
              marginLeft:
                !isEmpty(audioRef.current) &&
                (currentTime / audioRef.current.duration).toFixed(2) * 100 +
                  "%",
            }}></div>
        </div>
      </div>
      <div className="">
        {microphoneAccess === false && (
          <div>
            <p>
              Microphone access is denied. Please enable access to use the
              recorder.
            </p>
            <button onClick={requestMicrophoneAccess}>
              Request Microphone Access
            </button>
          </div>
        )}
      </div>
      <div className="audiorecorder_bottombar">
        <div className="audiorecorder_bottombar_left">
          <div
            onClickCapture={() =>
              volume == 0 ? handleVolumeChange(1.0) : handleVolumeChange(0.0)
            }>
            {volume == 0 ? (
              <img
                src="/assets/icn_media-control-volumeoff.svg"
                className="audiorecorder_icon"
              />
            ) : (
              <img
                src="/assets/icn_media-control-volume.svg"
                className="audiorecorder_icon"
              />
            )}
          </div>
        </div>
        <div className="audiorecorder_bottombar_middle">
          {!audioURL ? (
            <>
              <div
                onClick={handleStartStopRecording}
                disabled={microphoneAccess === false}>
                {isRecording ? (
                  <img
                    src="/assets/icn_media-control-stop.svg"
                    className="audiorecorder_icon"
                  />
                ) : (
                  <img
                    src="/assets/icn_media-control-record.svg"
                    className="audiorecorder_icon"
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <audio ref={audioRef} src={audioURL}></audio>
              <div onClick={decrementTime}>
                {" "}
                <img
                  src="/assets/icn_media-control-back.svg"
                  className="audiorecorder_icon"
                />
              </div>

              <div onClick={togglePlayPause}>
                {isPlaying ? (
                  <img
                    src="/assets/icn_media-control-pause.svg"
                    className="audiorecorder_icon"
                  />
                ) : (
                  <img
                    src="/assets/icn_media-control-play.svg"
                    className="audiorecorder_icon"
                  />
                )}
              </div>
              <div onClick={incrementTime}>
                <img
                  src="/assets/icn_media-control-forward.svg"
                  className="audiorecorder_icon"
                />
              </div>
            </>
          )}
        </div>
        <div className="audiorecorder_bottombar_right">
          <div onClick={handleDelete}>
            <img src="/assets/icn_trash.svg" className="audiorecorder_icon" />
          </div>
        </div>
      </div>

      <style jsx>{`
        .progress-bar-container {
          width: 550px;
          height: 108.92px;
          background-color: white;
          border: 0px solid #ddd;
          border-radius: 5px;
          overflow: hidden;
          margin-top: 10px;
          z-index: 1000;
        }
        .progress-bar {
          height: 100%;
          width: 0;
          background-color: purple;
          transition: width 0.1s linear;
          z-index: 0;
          margin-top: -108.92px;
        }
      `}</style>
      <div className="audiorecorder_savebtn">
        {audioURL && (
          <Togglebutton
            active={false}
            text={"AUFNAHME VERWENDEN"}
            clickEvent={saveAndProceed}
          />
        )}
      </div>
    </div>
  );
};

export default AudioRecorder;

import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdMenu } from "react-icons/md";

import "../styles/Home.css";
import "../styles/Camera.css";
import "../styles/MyMedia.css";
import { useLocation, useNavigate } from "react-router";
import DesktopTopNavbar from "../components/DesktopTopNavbar";
import Togglebutton from "../components/Togglebutton";
import Dropdown from "../components/Dropdown";
import Camera from "../components/Camera";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/Spinner";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function Create({
  isMobile,
  apiURL,
  authToken,
  sidebarOpen,
  setSidebarOpen,
  medienUploads,
  setMedienUploads,
  cameraPhotoshot,
  setCameraPhotoshots,
  localCompanyData,
  setLocalCompanyData,
  isUploading,
  destroySession,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [recordStreams, setRecordStreams] = useState([]);
  const [photoVorn, setPhotoVorn] = useState(null);
  const [photoLinks, setPhotoLinks] = useState(null);
  const [photoRechts, setPhotoRechts] = useState(null);
  const [photoLachend, setPhotoLachend] = useState(null);
  const [photoTraurig, setPhotoTraurig] = useState(null);
  const [photoWuetend, setPhotoWuetend] = useState(null);

  useEffect(() => {
    if (!isEmpty(photoVorn)) {
      setCameraPhotoshots([photoVorn]);
    }
  }, [photoVorn]);

  useEffect(() => {
    if (!isEmpty(photoLinks)) {
      setCameraPhotoshots([photoLinks]);
    }
  }, [photoLinks]);

  useEffect(() => {
    if (!isEmpty(photoRechts)) {
      setCameraPhotoshots([photoRechts]);
    }
  }, [photoRechts]);

  useEffect(() => {
    if (!isEmpty(photoTraurig)) {
      setCameraPhotoshots([photoTraurig]);
    }
  }, [photoTraurig]);

  useEffect(() => {
    if (!isEmpty(photoWuetend)) {
      setCameraPhotoshots([photoWuetend]);
    }
  }, [photoWuetend]);

  useEffect(() => {
    if (!isEmpty(photoLachend)) {
      setCameraPhotoshots([photoLachend]);
    }
  }, [photoLachend]);

  const insertLocalMedia = (e) => {
    if (e.target.files.length !== 0) {
      let file = e.target.files[0];
      let filetype;

      if (file.type.startsWith("image")) {
        filetype = "image";
      } else if (file.type.startsWith("video")) {
        filetype = "video";
      } else if (file.type.startsWith("audio")) {
        filetype = "audio";
      } else {
        alert("Unsupported file type");
        return;
      }

      if (file.size > 200 * 1024 * 1024) {
        alert("Datei zu groß (max 200MB)");
        return;
      }

      let newArray = [...medienUploads];
      newArray.push({
        localURL: URL.createObjectURL(file),
        files: file,
        filetype: filetype,
      });
      setMedienUploads(newArray);
    } else {
      return;
    }
  };

  const handlePhotoTaken = (dataUrl) => {
    if (location == "/create/gesichtscannen/vonvorne") {
      setPhotoVorn(dataUrl);
    }
    if (location == "/create/gesichtscannen/vonlinks") {
      setPhotoLinks(dataUrl);
    }
    if (location == "/create/gesichtscannen/vonrechts") {
      setPhotoRechts(dataUrl);
    }
    if (location == "/create/gesichtscannen/lachend") {
      setPhotoLachend(dataUrl);
    }
    if (location == "/create/gesichtscannen/traurig") {
      setPhotoTraurig(dataUrl);
    }
    if (location == "/create/gesichtscannen/wuetend") {
      setPhotoWuetend(dataUrl);
    }
  };

  const clickVonVorne = () => {
    navigate("/create/gesichtscannen/vonvorne");
  };
  const clickVonLinks = () => {
    navigate("/create/gesichtscannen/vonlinks");
  };
  const clickVonRechts = () => {
    navigate("/create/gesichtscannen/vonrechts");
  };
  const clickLachend = () => {
    navigate("/create/gesichtscannen/lachend");
  };
  const clickTraurig = () => {
    navigate("/create/gesichtscannen/traurig");
  };
  const clickWuetend = () => {
    navigate("/create/gesichtscannen/wuetend");
  };
  const clickToLeben = () => {
    navigate("/create/lebenkuratieren");
  };
  const clickToZeitablauf = () => {
    navigate("/create/zeitablaufbearbeiten");
  };
  const clickToLogin = () => {
    navigate("/login");
  };
  const saveAndProceed = () => {
    navigate("/create/stimmeaufnehmen");
  };

  const doNothing = () => {};

  const openImageUpload = () => {
    if (!isUploading) {
      let imgInput = document.getElementById("imgInp");
      imgInput.click();
    }
  };

  /* Draggable */
  const moveArrayItem = (array, fromIndex, toIndex) => {
    const item = array.splice(fromIndex, 1)[0];
    array.splice(toIndex, 0, item);
    return array;
  };

  const SortableItem = SortableElement(({ value }) => (
    <div className="mymedia_sortlist_listelement">
      <div className="mymedia_sortlist_listelement_left">
        {getFileType(value) == "image" ? (
          <img
            src={value}
            style={{ width: "30px", height: "30px", objectFit: "cover" }}
            alt="drag icon"
          />
        ) : (
          <video
            src={value}
            style={{ width: "30px", height: "30px", objectFit: "cover" }}
            alt="drag icon"
          />
        )}
      </div>
      <div className="mymedia_sortlist_listelement_middle">YY</div>
      <div className="mymedia_sortlist_listelement_right">
        Station in meinem Leben
      </div>
      <div className="mymedia_sortlist_listelement_draggable">
        <img src="/assets/icn_order.svg" alt="drag icon" />
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="mymedia_sortlist">
        <div className="mymedia_sortlist_toprow">
          <div className="mymedia_sortlist_toprow_left">Medienart</div>
          <div className="mymedia_sortlist_toprow_middle">Jahr</div>
          <div className="mymedia_sortlist_toprow_right">Titel</div>
        </div>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setLocalCompanyData((prevData) => {
      const newMedia = moveArrayItem([...prevData.media], oldIndex, newIndex);
      return {
        ...prevData,
        media: newMedia,
        lastModified: getTodayAsUnixDate(),
      };
    });
  };
  /*
  const [characterData, setCharacterData] = useState({
    soziodemografie: {
      gender: "maennlich",
      geburtsort: "",
      wohnort: "",
      weiterWohnort: "",
      zeitraum: "",
    },
    hauptberuf: { berufe: [], sonstiges: "" },
  });
  */

  useEffect(() => {
    if (
      location !== "/create/gesichtscannen/vonvorne" &&
      location !== "/create/gesichtscannen/vonlinks" &&
      location !== "/create/gesichtscannen/vonrechts" &&
      location !== "/create/gesichtscannen/lachend" &&
      location !== "/create/gesichtscannen/traurig" &&
      location !== "/create/gesichtscannen/wuetend"
    ) {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    }
  }, [location]);

  useEffect(() => {
    return () => {
      recordStreams.forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
      setRecordStreams([]);
    };
  }, []);

  function getFileType(filename) {
    // Define the file extensions for each type
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".tiff",
      ".svg",
    ];
    const videoExtensions = [
      ".mp4",
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".mkv",
      ".webm",
    ];
    const audioExtensions = [".mp3", ".wav", ".ogg", ".flac", ".aac", ".m4a"];

    // Convert filename to lowercase to make the check case-insensitive
    const lowerCaseFilename = filename.toLowerCase();

    // Check the file extension
    if (imageExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "image";
    } else if (videoExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "video";
    } else if (audioExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "audio";
    } else {
      return "unknown"; // if the file type is not recognized
    }
  }

  const removePhoto = (index) => {
    let helpArr = { ...localCompanyData };
    helpArr.photosFace.splice(index, 1);
    helpArr.lastModified = getTodayAsUnixDate();

    setLocalCompanyData(helpArr);
  };

  const removeMedia = (index) => {
    let helpArr = { ...localCompanyData };
    helpArr.media.splice(index, 1);
    helpArr.lastModified = getTodayAsUnixDate();

    setLocalCompanyData(helpArr);
  };

  return (
    <>
      {isMobile ? (
        <div className="home_mobile"></div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            authToken={authToken}
            destroySession={destroySession}
          />
          <div className="home_split" onClick={() => setSidebarOpen(false)}>
            <>
              {(location == "/create/gesichtscannen/vonvorne" ||
                location == "/create/gesichtscannen/vonlinks" ||
                location == "/create/gesichtscannen/vonrechts" ||
                location == "/create/gesichtscannen/lachend" ||
                location == "/create/gesichtscannen/traurig" ||
                location == "/create/gesichtscannen/wuetend") && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">Gesicht scannen</div>
                    <div className="home_split_left_text">
                      Die Kamera ist sauber. Der Raum, in dem du dich befindest,
                      ist möglichst hell. Dein Gesicht ist gut ausgeleuchtet.
                      Kein Schatten, Haare oder irgendetwas anderes verdein
                      Gesicht. Die Kamera befindet sich auf Augenhöhe. Keine
                      Auf- oder Untersicht (lass dir gerne von einer anderen
                      Person helfen). Der Hintergrund ist ruhig und hat einen
                      guten Kontrast zu deinem Gesicht.
                    </div>
                    <div className="home_split_left_images_row">
                      <div className="home_split_left_images_row_images">
                        {!isEmpty(localCompanyData.photosFace) &&
                          localCompanyData.photosFace.map((photo, index) => (
                            <div className="home_split_left_images_row_images_imgbox">
                              <img
                                src={photo}
                                alt="Taken photo"
                                className={
                                  photo
                                    ? "home_split_left_images_row_images_img"
                                    : "d-none"
                                }
                              />
                              <div
                                className="home_split_left_images_row_images_delete"
                                onClick={() => removePhoto(index)}>
                                x
                              </div>
                            </div>
                          ))}
                        {isUploading && (
                          <div className="home_split_left_images_row_images_img">
                            <Spinner type="white" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="home_split_left_buttons_row">
                      <div className="home_split_left_buttons_row_col">
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/vonvorne"
                              ? true
                              : false
                          }
                          text={"von vorne"}
                          clickEvent={clickVonVorne}
                        />
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/vonlinks"
                              ? true
                              : false
                          }
                          text={"von links"}
                          clickEvent={clickVonLinks}
                        />
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/vonrechts"
                              ? true
                              : false
                          }
                          text={"von rechts"}
                          clickEvent={clickVonRechts}
                        />
                      </div>
                      <div className="home_split_left_buttons_row_col">
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/lachend"
                              ? true
                              : false
                          }
                          text={"lachend"}
                          clickEvent={clickLachend}
                        />
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/traurig"
                              ? true
                              : false
                          }
                          text={"traurig"}
                          clickEvent={clickTraurig}
                        />{" "}
                        <Togglebutton
                          active={
                            location == "/create/gesichtscannen/wuetend"
                              ? true
                              : false
                          }
                          text={"wütend"}
                          clickEvent={clickWuetend}
                        />
                      </div>
                    </div>
                    {!isEmpty(localCompanyData.photosFace) && (
                      <div className="audiorecorder_savebtn">
                        <Togglebutton
                          active={false}
                          text={"Aufnahme verwenden"}
                          clickEvent={saveAndProceed}
                        />
                      </div>
                    )}
                  </div>
                  <div className="home_split_right">
                    <div className="camera_div">
                      <Camera
                        onPhotoTaken={handlePhotoTaken}
                        recordStreams={recordStreams}
                        setRecordStreams={setRecordStreams}
                        isUploading={isUploading}
                      />
                    </div>
                  </div>
                </>
              )}

              {location == "/create/characterdefinieren" && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">
                      Definiere deinen Charakter
                    </div>
                    <div className="home_split_left_text">
                      Wähle die Eigenschaften aus, die Dich ausmachen. Und
                      ergänze sie gerne, wenn etwas noch fehlt.
                    </div>
                    <Dropdown
                      title={"Soziodemografie"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Ausbildung"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                  </div>
                  <div className="home_split_right">
                    <Dropdown
                      title={"Hauptberuf"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Status"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Hobbys"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Lebenseinstellung"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Verhalten"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Fähigkeiten"}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <Dropdown
                      title={"Und so weiter..."}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                    />
                    <div className="home_split_right_nextBtnRow">
                      <Togglebutton
                        active={false}
                        text={"SPEICHERN"}
                        clickEvent={clickToLeben}
                      />
                    </div>
                  </div>
                </>
              )}
              {location == "/create/lebenkuratieren" && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">
                      Dein Leben in Bildern
                    </div>
                    <div className="home_split_left_text">
                      Hier kannst du alle deine Fotos und Filme hochladen, die
                      du gerne in Erinnerung halten möchtest. Außerdem kannst du
                      Audios aufnehmen, um deine Geschichte zu erzählen.
                      <br /> <br />
                      Jedes hochgeladene Medium kannst du mit Schlagworten und
                      einer Beschreibung versehen.
                      <br />
                      <br />
                      Alle Medien kannst du dann in eine passende Reihenfolge
                      bringen als deine ganz persönliche Lebensgeschichte.
                    </div>
                    <div className="home_split_left_row">
                      <div className="dropdown_body_row_radiobox">
                        <div className="dropdown_body_row_radiobox_left">
                          <Togglebutton
                            active={false}
                            text={"Medien hochladen"}
                            clickEvent={openImageUpload}
                            isUploading={isUploading}
                          />
                        </div>
                        <div className="dropdown_body_row_radiobox_left">
                          Lade Bilder oder Videos hoch.
                        </div>
                      </div>
                      <div className="dropdown_body_row_radiobox">
                        <div className="dropdown_body_row_radiobox_left">
                          <Togglebutton
                            active={false}
                            text={"Audio aufnehmen"}
                            clickEvent={doNothing}
                          />
                        </div>
                        <div className="dropdown_body_row_radiobox_left">
                          Nimm Audio auf.
                        </div>
                      </div>
                      <div className="dropdown_body_row_radiobox">
                        <div className="dropdown_body_row_radiobox_left">
                          <Togglebutton
                            active={false}
                            text={"Deine Geschichte"}
                            clickEvent={clickToZeitablauf}
                          />
                        </div>
                        <div className="dropdown_body_row_radiobox_left">
                          Erzähle deine Geschichte.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home_split_right">
                    <div className="mymedia">
                      <div className="mymedia_headline">Deine Medien</div>
                      <div className="mymedia_toprow">
                        <div className="mymedia_toprow_text">
                          Sortieren nach
                        </div>
                        <select className="mymedia_toprow_select">
                          <option>Medienart</option>
                          <option>Bilder</option>
                          <option>Videos</option>
                          <option>Ton</option>
                        </select>
                        <select className="mymedia_toprow_select">
                          <option>Jahr</option>
                          <option>2020-2024</option>
                          <option>2010-2019</option>
                          <option>2000-2009</option>
                          <option>1990-1999</option>
                          <option>1980-1989</option>
                          <option>1970-1979</option>
                          <option>1960-1969</option>
                          <option>1950-1959</option>
                          <option>1940-1949</option>
                          <option>1930-1939</option>
                          <option>1920-1929</option>
                          <option>1910-1919</option>
                          <option>1900-1909</option>
                        </select>
                        <select className="mymedia_toprow_select">
                          <option>Alphabet/Upload</option>
                        </select>
                        <div className="mymedia_toprow_iconbox">
                          <img
                            className="mymedia_toprow_iconbox_icon"
                            src="/assets/icn_search.svg"
                          />
                        </div>
                      </div>
                      <div className="mymedia_list">
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png, .mp4, .mov, .avi, .mkv, .mp3, .wav, .ogg"
                          id={"imgInp"}
                          name="files[]"
                          maxFileSize={100 * 1024 * 1024} // 100MB in bytes
                          className="createService_inputBtn d-none"
                          onChange={(e) => insertLocalMedia(e)}
                        />
                        {/*
                        medienUploads.map((img) => {
                          return (
                            <>
                              {img.filetype == "image" && (
                                <div
                                  className="mymedia_list_element"
                                  key={img.localURL}>
                                  <div className="mymedia_list_element_top">
                                    <img
                                      src={img.localURL}
                                      className="mymedia_list_element_top_img"
                                    />
                                  </div>
                                  <div className="mymedia_list_element_bottom">
                                    Station aus meinem Leben
                                  </div>
                                </div>
                              )}
                              {img.filetype == "video" && (
                                <div className="mymedia_list_element">
                                  <div className="mymedia_list_element_top">
                                    <video
                                      src={img.localURL}
                                      className="mymedia_list_element_top_img"
                                      controls={true}
                                    />
                                  </div>
                                  <div className="mymedia_list_element_bottom">
                                    Station aus meinem Leben
                                  </div>
                                </div>
                              )}
                              {img.filetype == "audio" && (
                                <div className="mymedia_list_element">
                                  <div className="mymedia_list_element_top">
                                    <audio
                                      src={img.localURL}
                                      className="mymedia_list_element_top_img"
                                      controls={true}
                                    />
                                  </div>
                                  <div className="mymedia_list_element_bottom">
                                    Station aus meinem Leben
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })
                        */}
                        {!isEmpty(localCompanyData) &&
                          localCompanyData.media.map((img, index) => {
                            return (
                              <>
                                {getFileType(img) == "image" && (
                                  <>
                                    <div
                                      className="mymedia_list_element"
                                      key={img}>
                                      <div
                                        className="mymedia_list_remove"
                                        onClick={() => removeMedia(index)}>
                                        x
                                      </div>
                                      <div className="mymedia_list_element_top">
                                        <img
                                          src={img}
                                          className="mymedia_list_element_top_img"
                                        />
                                      </div>
                                      <div className="mymedia_list_element_bottom">
                                        Station aus meinem Leben
                                      </div>
                                    </div>
                                  </>
                                )}
                                {getFileType(img) == "video" && (
                                  <>
                                    <div
                                      className="mymedia_list_element"
                                      key={img}>
                                      <div
                                        className="mymedia_list_remove"
                                        onClick={() => removeMedia(index)}>
                                        x
                                      </div>
                                      <div className="mymedia_list_element_top">
                                        <video
                                          src={img}
                                          className="mymedia_list_element_top_img"
                                          controls={true}
                                        />
                                      </div>
                                      <div className="mymedia_list_element_bottom">
                                        Station aus meinem Leben
                                      </div>
                                    </div>
                                  </>
                                )}
                                {getFileType(img) == "audio" && (
                                  <>
                                    <div
                                      className="mymedia_list_element"
                                      key={img}>
                                      <div
                                        className="mymedia_list_remove"
                                        onClick={() => removeMedia(index)}>
                                        x
                                      </div>

                                      <div className="mymedia_list_element_top">
                                        <audio
                                          src={img}
                                          className="mymedia_list_element_top_img"
                                          controls={true}
                                        />
                                      </div>
                                      <div className="mymedia_list_element_bottom">
                                        Station aus meinem Leben
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })}
                        {isUploading && (
                          <div className="home_split_left_images_row_images_img">
                            <Spinner type="white" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {location == "/create/zeitablaufbearbeiten" && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">
                      Deine Geschichte
                    </div>
                    <div className="home_split_left_text">
                      Hier kannst du alle Bilder, Filme und Audios in die
                      Reihenfolge bringen, wie du die Geschichte deines Lebens
                      erzählen möchtest.
                    </div>
                  </div>
                  <div className="home_split_right">
                    <div className="mymedia">
                      <div className="mymedia_headline">Deine Medien</div>
                    </div>
                    <SortableList
                      items={localCompanyData.media}
                      onSortEnd={onSortEnd}
                    />

                    <div className="home_split_right_nextBtnRow">
                      <Togglebutton
                        active={false}
                        text={"SPEICHERN"}
                        clickEvent={clickToLogin}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles/App.css";
import { isEmpty } from "./functions/isEmpty";
import useFetch from "./functions/useFetch";

import Home from "./screens/Home";
import useScreenType from "./functions/useScreenType";
import { LanguageProvider } from "./contexts/LanguageContext";
import Legal from "./screens/Legal";
import Create from "./screens/Create";
import Login from "./screens/Login";
import Meet from "./screens/Meet";
import URLPreloadFromArray from "./components/URLPreloadFromArray";
import { getTodayAsUnixDate } from "./functions/getTodayAsUnixDate";

const developerMode = true;

export default function App() {
  let apiURL = "https://cloud.meternity.ai";
  if (developerMode) {
    apiURL = "https://www.servermeternity.servist.de";
  }

  /* Auth Token */
  const [authToken, setAuthToken] = useState("");

  /* userdata */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [shadowUserID, setShadowUserID] = useState("");
  const [localCompanyData, setLocalCompanyData] = useState({
    firstname: "",
    lastname: "",
    birthdate: "",
    email: "",
    gender: "",
    birthplace: "",
    adress: "",
    adressAlt: "",
    timeOnAdress: "",
    jobs: [],
    photosFace: [],
    soundsVoice: [],
    media: [],
    chats: [],
    lastModified: 0,
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [isUploading, setIsUploading] = useState(false);
  const [reload, setReload] = useState(false);

  const isMobile = useScreenType();

  /* global states */
  const [medienUploads, setMedienUploads] = useState([]);
  const [cameraPhotoshots, setCameraPhotoshots] = useState([]);
  const [stimmeaufgenommen, setStimmeaufgenommen] = useState([]);
  const [myName, setMyName] = useState("");
  const [myMessages, setMyMessages] = useState([]);

  const [toUpload, setToUpload] = useState([]);

  const {
    data: userData,
    loading: loadingUser,
    error: errorUser,
    setData: setUserData,
  } = useFetch(
    `${apiURL}/api/users/`,
    authToken,
    "GET",
    null,
    [authToken] // Additional dependencies, if needed
  );

  const {
    data: companies,
    loading: loadingCompanies,
    error: errorcompanies,
  } = useFetch(
    `${apiURL}/api/companies/`,
    authToken,
    "GET",
    null,
    [authToken, reload] // Additional dependencies, if needed
  );

  const {
    data: shadowUserData,
    loading: loadingShadowUserData,
    error: errorShadowUserData,
    setData: setShadowUserData,
  } = useFetch(
    `${apiURL}/api/shadowUser/?id=` + shadowUserID,
    authToken,
    "GET",
    null,
    [reload, shadowUserID] // Additional dependencies, if needed
  );

  useEffect(() => {
    setEmail(localCompanyData.email);
    setUsername(localCompanyData.firstname);
  }, [localCompanyData]);

  /* Daten speichern*/
  useEffect(async () => {
    let jsonstring = localStorage.getItem("@MeternitylocalCompanyData");
    if (
      (isEmpty(jsonstring) && localCompanyData?.lastModified >= 0) ||
      JSON.parse(jsonstring).lastModified < localCompanyData.lastModified
    ) {
      localStorage.setItem(
        "@MeternitylocalCompanyData",
        JSON.stringify(localCompanyData)
      );
      if (!isEmpty(userData)) {
        const resa = await fetch(
          `${apiURL}/api/companies/index.php?id=` + userData.companyID,
          {
            method: "POST",
            headers: {
              //   Authorization: `Bearer ${authToken?.JWT}`,
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: JSON.stringify({
              firstname: localCompanyData.firstname,
              lastname: localCompanyData.lastname,
              birthdate: localCompanyData.birthplace,
              gender: localCompanyData.gender,
              birthplace: localCompanyData.birthplace,
              adress: localCompanyData.adress,
              adressAlt: localCompanyData.adressAlt,
              timeOnAdress: localCompanyData.timeOnAdress,
              jobs: JSON.stringify(localCompanyData.jobs),
              photosFace: JSON.stringify(localCompanyData.photosFace),
              soundsVoice: JSON.stringify(localCompanyData.soundsVoice),
              media: JSON.stringify(localCompanyData.media),
              lastModified: localCompanyData.lastModified,
              chats: JSON.stringify(localCompanyData.chats),
              status: 1,
            }),
          }
        );
        setReload(!reload);
      }
    }
  }, [authToken, localCompanyData, userData]);

  useEffect(() => {
    setLocalCompanyData({
      ...localCompanyData,
      lastModified: getTodayAsUnixDate(),
    });
  }, [userData]);

  /* Daten holen*/
  useEffect(() => {
    // get Data from localstorage
    let jsonstring = localStorage.getItem("@MeternitylocalCompanyData");
    if (
      !isEmpty(jsonstring) ||
      !isEmpty(jsonstring.lastModified) ||
      jsonstring.lastModified > localCompanyData?.lastModified
    ) {
      setLocalCompanyData(JSON.parse(jsonstring));
    }
  }, [authToken]);

  const handleStoreInDatabase = async (newUrl, source) => {
    if (source == "media") {
      let helpArray = [...localCompanyData.media];
      helpArray.push(newUrl);
      setLocalCompanyData({
        ...localCompanyData,
        media: helpArray,
        lastModified: getTodayAsUnixDate(),
      });
      setMedienUploads([]);
    }
    if (source == "camera") {
      let helpArray = [...localCompanyData.photosFace];
      helpArray.push(newUrl);
      setLocalCompanyData({
        ...localCompanyData,
        photosFace: helpArray,
        lastModified: getTodayAsUnixDate(),
      });
      setCameraPhotoshots([]);
    }
    if (source == "voice") {
      let helpArray = [...localCompanyData.soundsVoice];
      helpArray.push(newUrl);
      setLocalCompanyData({
        ...localCompanyData,
        soundsVoice: helpArray,
        lastModified: getTodayAsUnixDate(),
      });
      setStimmeaufgenommen([]);
    }
    setIsUploading(false);

    if (!isEmpty(shadowUserData[0])) {
      let urls = [];
      JSON.parse(shadowUserData[0].mediaFileURLs).forEach((url) =>
        urls.push(url)
      );
      urls.push(newUrl);
      let copyShadowUser = [...shadowUserData];
      copyShadowUser[0].mediaFileURLs = JSON.stringify(urls);
      setShadowUserData(copyShadowUser);

      const resa = await fetch(
        `${apiURL}/api/shadowUser/index.php?id=` + shadowUserID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            mediaFileURLs: JSON.stringify(urls),
          }),
        }
      );

      // await resa.text().then((value) => uploadEnds(value));
    }
  };

  // get a shadowUserID
  useEffect(() => {
    if (isEmpty(shadowUserID)) {
      const jsonValue = localStorage.getItem("@shadowUserID");
      console.log(jsonValue);
      if (isEmpty(jsonValue)) {
        getShadowUserId();
      } else {
        setShadowUserID(jsonValue);
      }
    }
  }, []);

  const storeShadowUserID = (idVal) => {
    localStorage.setItem("@shadowUserID", idVal);
    setShadowUserID(idVal);
  };

  const getShadowUserId = async () => {
    const resa = await fetch(`${apiURL}/api/shadowUser/index.php`, {
      method: "PUT",
      headers: {
        //   Authorization: `Bearer ${authToken?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        name: localCompanyData.firstname,
      }),
    });

    await resa.text().then((value) => storeShadowUserID(value));
  };

  const handleUploadForImage = async () => {
    if (!isEmpty(toUpload)) {
      setToUpload([]);
      setIsUploading(true);

      const formData = new FormData();
      formData.append("files[]", toUpload[0].file[0].files);

      const res = await fetch(`${apiURL}/api/images/process.php`, {
        headers: {
          // Authorization: `Bearer ${authToken?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      const value = await res.text();
      handleStoreInDatabase(value, toUpload[0].source);

      // Clear the upload queue
    }
  };

  useEffect(() => {
    if (!isEmpty(toUpload)) {
      if (
        !isEmpty(toUpload[0].file[0]) &&
        toUpload[0].file[0].localURL.length > 5
      ) {
        handleUploadForImage();
      }
    }
  }, [toUpload]);

  useEffect(async () => {
    if (!isEmpty(myName)) {
      const resa = await fetch(
        `${apiURL}/api/shadowUser/index.php?id=` + shadowUserID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            name: myName,
          }),
        }
      );

      // await resa.text().then((value) => uploadEnds(value));
    }
  }, [myName]);

  useEffect(async () => {
    if (!isEmpty(myMessages)) {
      const resa = await fetch(
        `${apiURL}/api/shadowUser/index.php?id=` + shadowUserID,
        {
          method: "POST",
          headers: {
            //   Authorization: `Bearer ${authToken?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          body: JSON.stringify({
            chat: JSON.stringify(myMessages),
          }),
        }
      );

      // await resa.text().then((value) => uploadEnds(value));
    }
  }, [myMessages]);

  useEffect(() => {
    if (localCompanyData.lastModified == 0 && !isEmpty(userData)) {
      let newData = {};
      companies.map((company) => {
        if (company.id == userData.companyID) {
          newData = company;
        }
      });
      newData.jobs = JSON.parse(newData.jobs);
      newData.photosFace = JSON.parse(newData.photosFace);
      newData.soundsVoice = JSON.parse(newData.soundsVoice);
      newData.media = JSON.parse(newData.media);
      newData.chats = JSON.parse(newData.chats);
      setLocalCompanyData(newData);
    }
  }, [userData]);

  useEffect(() => {
    if (!isEmpty(medienUploads)) {
      setToUpload([
        {
          file: [medienUploads[0]],
          source: "media",
        },
      ]);
    }
  }, [medienUploads]);
  useEffect(() => {
    if (!isEmpty(cameraPhotoshots)) {
      setToUpload([
        {
          file: [cameraPhotoshots[0]],
          source: "camera",
        },
      ]);
    }
  }, [cameraPhotoshots]);
  useEffect(() => {
    if (!isEmpty(stimmeaufgenommen)) {
      setToUpload([
        {
          file: [stimmeaufgenommen[0]],
          source: "voice",
        },
      ]);
    }
  }, [stimmeaufgenommen]);

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmeternity");
      localStorage.removeItem("@MeternitylocalCompanyData");
      setAuthToken();
      setUserData();
      setLocalCompanyData({
        firstname: "",
        lastname: "",
        birthdate: "",
        email: "",
        gender: "",
        birthplace: "",
        adress: "",
        adressAlt: "",
        timeOnAdress: "",
        jobs: [],
        photosFace: [],
        soundsVoice: [],
        media: [],
        chats: [],
        lastModified: 0,
      });
    } catch (e) {
      // saving error
    }
  };

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmeternity");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setAuthToken({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  if (isEmpty(authToken) && localStorage.getItem("@jwtmeternity")) {
    getData();
  }

  const asasa = () => {};

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmeternity", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };
    const res = await fetch(`${apiURL}/api/authenticate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
      setIsUploading(false);
      localStorage.removeItem("@MeternitylocalCompanyData");
      setLocalCompanyData({
        firstname: "",
        lastname: "",
        birthdate: "",
        email: "",
        gender: "",
        birthplace: "",
        adress: "",
        adressAlt: "",
        timeOnAdress: "",
        jobs: [],
        photosFace: [],
        soundsVoice: [],
        media: [],
        chats: [],
        lastModified: 0,
      });
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const signUp = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };
    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmeternity", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const res = await fetch(`${apiURL}/api/signup.php`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        username: username,
      }),
    });

    // check if response exists
    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      // check if response has jwt
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setAuthToken(setAuthToken({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Registrierung fehlgeschlagen");
      }
      setIsUploading(false);
    } else {
      // @Todo: Handle errors
      console.log(res.status, res.statusText);
      setIsUploading(false);
    }
  };

  const [templateVideos, setTemplateVideos] = useState([
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-1.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-2.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-3.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-4.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
  ]);

  return (
    <div className="app">
      <LanguageProvider>
        <URLPreloadFromArray mediaURLArray={templateVideos} />
        <Router>
          <Routes>
            {["/", "/create", "/create/stimmeaufnehmen", "/meet"].map(
              (path) => (
                <Route
                  path={path}
                  element={
                    <Home
                      isMobile={isMobile}
                      apiURL={apiURL}
                      destroySession={destroySession}
                      authToken={authToken}
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      medienUploads={medienUploads}
                      setMedienUploads={setMedienUploads}
                      stimmeaufgenommen={stimmeaufgenommen}
                      setStimmeaufgenommen={setStimmeaufgenommen}
                      cameraPhotoshots={setCameraPhotoshots}
                      setCameraPhotoshots={setCameraPhotoshots}
                      companies={companies}
                      localCompanyData={localCompanyData}
                      setLocalCompanyData={setLocalCompanyData}
                      isUploading={isUploading}
                      setIsUploading={setIsUploading}
                    />
                  }
                />
              )
            )}
            {[
              "/create/characterdefinieren",
              "/create/characterdefinieren",
              "/create/lebenkuratieren",
              "/create/characterdefinieren",
              "/create/zeitablaufbearbeiten",
              "/create/avatarchange",
              "/create/geschichteerzaehlen",
              "/create/gesichtscannen/vonvorne",
              "/create/gesichtscannen/vonlinks",
              "/create/gesichtscannen/vonrechts",
              "/create/gesichtscannen/lachend",
              "/create/gesichtscannen/traurig",
              "/create/gesichtscannen/wuetend",
            ].map((path) => (
              <Route
                path={path}
                element={
                  <Create
                    isMobile={isMobile}
                    apiURL={apiURL}
                    authToken={authToken}
                    destroySession={destroySession}
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    medienUploads={medienUploads}
                    setMedienUploads={setMedienUploads}
                    stimmeaufgenommen={stimmeaufgenommen}
                    setStimmeaufgenommen={setStimmeaufgenommen}
                    cameraPhotoshots={setCameraPhotoshots}
                    setCameraPhotoshots={setCameraPhotoshots}
                    localCompanyData={localCompanyData}
                    setLocalCompanyData={setLocalCompanyData}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                }
              />
            ))}

            {[
              "/meet",
              "/meet/:id",
              "/meet/:id/explore",
              "/meet/:id/slideshow",
              "/meet/:id/talk",
            ].map((path) => (
              <Route
                path={path}
                element={
                  <Meet
                    isMobile={isMobile}
                    apiURL={apiURL}
                    authToken={authToken}
                    destroySession={destroySession}
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    myName={myName}
                    setMyName={setMyName}
                    myMessages={myMessages}
                    setMyMessages={setMyMessages}
                    companies={companies}
                    localCompanyData={localCompanyData}
                    setLocalCompanyData={setLocalCompanyData}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                  />
                }
              />
            ))}

            <Route
              path="/login"
              element={
                <Login
                  isMobile={isMobile}
                  apiURL={apiURL}
                  authToken={authToken}
                  destroySession={destroySession}
                  email={email}
                  username={username}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  setUsername={setUsername}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  signIn={signIn}
                  signUp={signUp}
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                  localCompanyData={localCompanyData}
                  setLocalCompanyData={setLocalCompanyData}
                />
              }
            />
            <Route
              path="/legal"
              element={
                <Legal destroySession={destroySession} authToken={authToken} />
              }
            />
          </Routes>
          <></>
        </Router>
      </LanguageProvider>
    </div>
  );
}

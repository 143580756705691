import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdFitnessCenter,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdMonetizationOn,
  MdOutlineCampaign,
  MdOutlineMonetizationOn,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPets,
  MdPlayCircleFilled,
  MdPlayCircleOutline,
  MdRestaurant,
  MdSearch,
  MdShield,
  MdShoppingCart,
  MdSports,
  MdSportsMma,
} from "react-icons/md";
import "../styles/Home.css";
import "../styles/Togglebutton.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../contexts/LanguageContext";

export default function Togglebutton({
  isMobile,

  authToken,
  isUploading,
  companyData,
  active,
  text,
  clickEvent,
  type,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();

  if (type == "small") {
    return (
      <>
        {active ? (
          <div
            className="TogglebuttonSmall_active"
            onClick={() => clickEvent("/meet")}>
            {text}
          </div>
        ) : (
          <div
            className="TogglebuttonSmall"
            onClick={() => clickEvent("/meet")}>
            {text}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {active ? (
        <div className="Togglebutton_active">{text}</div>
      ) : (
        <>
          {isUploading ? (
            <div className="Togglebutton_disabled">{text}</div>
          ) : (
            <div className="Togglebutton" onClick={() => clickEvent("/meet")}>
              {text}
            </div>
          )}
        </>
      )}
    </>
  );
}

const ArrowBack = styled(MdArrowBack)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-right: 5px;
`;

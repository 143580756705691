import React, { useRef, useState, useEffect } from "react";
import "../styles/Home.css";
import "../styles/Camera.css";
import Spinner from "./Spinner";

function Camera({
  onPhotoTaken,
  recordStreams,
  setRecordStreams,
  isUploading,
}) {
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [cameraActive, setCameraActive] = useState(false);

  useEffect(() => {
    getVideo();
    return () => {
      closeCamera();
    };
  }, []);

  const getVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
        setCameraActive(true);
        let helpArr = recordStreams;
        helpArr.push(stream);
        setRecordStreams(helpArr);
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setCameraActive(false);
      });
  };

  const takePhoto = () => {
    if (isUploading) {
      return;
    }
    let video = videoRef.current;
    let photo = photoRef.current;

    const width = 1200; //photo.clientWidth;
    const height = 1200; // photo.clientHeight;

    photo.width = width;
    photo.height = height;

    let context = photo.getContext("2d");
    context.translate(width, 0);
    context.scale(-1, 1);
    context.drawImage(video, 0, 0, width, height);
    context.restore();

    const dataUrl = photo.toDataURL("image/png");
    setHasPhoto(true);
    // Convert dataUrl to Blob
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "photo.png", { type: "image/png" });
        const localURL = URL.createObjectURL(file);
        const filetype = file.type;

        let imgObject = {
          localURL: URL.createObjectURL(file),
          files: file,
          filetype: filetype,
        };
        // Pass the object to the parent component
        onPhotoTaken(imgObject);
      })
      .catch((err) => {
        console.error("Error converting dataUrl to Blob: ", err);
      });
  };

  const closeCamera = () => {
    let video = videoRef.current;
    if (video && video.srcObject) {
      const stream = video.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => track.stop());
      video.srcObject = null;
      setCameraActive(false);
    }
  };

  return (
    <div className="camera">
      <div className="camera-container" onClick={getVideo}>
        <video
          ref={videoRef}
          style={{
            transform: "scaleX(-1)",
          }}></video>
        {!cameraActive && (
          <div className="no-camera-access" onClick={getVideo}>
            Du hast deine Kamera nicht freigegeben. Ändere dies in den
            Einstellungen deines Browsers.
          </div>
        )}
      </div>
      <div className="camera_div_bottom">
        <div
          className={
            isUploading
              ? "camera_div_bottom_takePhoto_disabled"
              : "camera_div_bottom_takePhoto"
          }
          onClick={takePhoto}>
          <div
            className={
              isUploading
                ? "camera_div_bottom_takePhoto_inner_disabled"
                : "camera_div_bottom_takePhoto_inner"
            }>
            {isUploading && <Spinner type="white" />}
          </div>
        </div>
      </div>

      {
        <div className={"result " + (hasPhoto ? "hasPhoto" : " d-none")}>
          <canvas
            ref={photoRef}
            style={{
              objectFit: "cover",
              width: "30px",
              height: "30px",
              zIndex: "-9999",
            }}></canvas>
          <div className="blackbox"></div>
        </div>
      }
    </div>
  );
}

export default Camera;

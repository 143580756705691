import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdClose, MdMenu } from "react-icons/md";

import "../styles/Home.css";
import "../styles/Explore.css";
import { useLocation, useNavigate, useParams } from "react-router";
import DesktopTopNavbar from "../components/DesktopTopNavbar";
import Togglebutton from "../components/Togglebutton";
import URLPreloadFromArray from "../components/URLPreloadFromArray";
import Dropdown from "../components/Dropdown";
import AudioRecorder from "../components/AudioRecorder";
import { isEmpty } from "../functions/isEmpty";
import { useRef } from "react";
import Spinner from "../components/Spinner";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function Meet({
  isMobile,
  apiURL,
  authToken,
  myName,
  setMyName,
  sidebarOpen,
  setSidebarOpen,
  myMessages,
  setMyMessages,
  companies,
  localCompanyData,
  setLocalCompanyData,
  destroySession,
}) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const { id } = useParams();
  const [sliderCount, setSliderCounter] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [secondCount, setSecondCount] = useState(0);
  const [limit, setLimit] = useState(180);
  const [isPlayMode, setIsPlayMode] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [showVideo, setShowVideo] = useState(true);
  const videoRef = useRef(null);
  const [activeCompany, setActiveCompany] = useState({});
  const [exploreFiles, setExploreFiles] = useState([]);
  const [initialInstructions, setInitialInstruction] = useState({});

  useEffect(() => {
    if (!isEmpty(companies)) {
      companies.map((company) => {
        if (id == company.id) {
          setActiveCompany(company);

          let exploreF = [];
          JSON.parse(company.media).map((file, index) => {
            exploreF.push({
              id: index + 1,
              url: file,
              video: !isEmpty(JSON.parse(company.storytellingVideos)[index])
                ? JSON.parse(company.storytellingVideos)[index]
                : "",
              title: !isEmpty(JSON.parse(company.storytellingTitles)[index])
                ? JSON.parse(company.storytellingTitles)[index]
                : "Ein Ausschnit aus meinem Leben",
            });
          });
          setExploreFiles(exploreF);
          setLimit(JSON.parse(company.media).length * 30);
          setInitialInstruction({
            ...company,
            instruction:
              "Sage nicht dass du eine K.I. bist, sondern tu so, als wenn du diese Person wärest. Denke dir Dinge aus, die zu dieser Personen passen könnten, wenn ich dir Fragen stelle zu etwas, das du noch nicht weißt. Sprich in Du und nicht in Sie.",
          });
        }
      });
    }
  }, [companies]);

  const turnOffVideo = () => {
    setShowVideo(false);
    setIsMuted(true);
  };

  const turnOnVideo = () => {
    setShowVideo(true);
    setIsMuted(false);
  };

  useEffect(() => {
    let intervalId;
    if (isPlaying) {
      intervalId = setInterval(() => {
        setSecondCount((prevCount) => {
          if (prevCount >= limit) {
            return 0;
          }
          return prevCount + 1;
        });
      }, 1000); // Update count every 1000ms (1 second)
    }

    // Cleanup interval on component unmount or when isPlaying changes
    return () => clearInterval(intervalId);
  }, [isPlaying, limit]);

  const toggleIsPlaying = () => {
    if (!isPlayMode) {
      return;
    }
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }

    setIsPlaying(!isPlaying);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const paddedMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${paddedMinutes}:${paddedSeconds}`;
  }

  const clickOnExplore = () => {
    navigate("/meet/" + id + "/explore");
  };

  const clickOnTalk = () => {
    navigate("/meet/" + id + "/talk");
  };

  const clickOnExploreSlideshow = () => {
    navigate("/meet/" + id + "/slideshow");
    if (videoRef.current) {
      videoRef.current.play();
    }
    setIsPlaying(true);
  };

  const openSlideShow = (index) => {
    setIsPlayMode(true);
    navigate("/meet/" + id + "/slideshow");
    setSliderCounter(index);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setIsPlaying(false);

    setSecondCount(index * 30);
  };

  const calculateWidth = () => {
    return (secondCount / limit) * 100;
  };

  useEffect(() => {
    if (secondCount <= 0 && secondCount < 30) {
      setSliderCounter(0);
    } else if (secondCount >= 30 && secondCount < 60) {
      setSliderCounter(1);
    } else if (secondCount >= 60 && secondCount < 90) {
      setSliderCounter(2);
    } else if (secondCount >= 90 && secondCount < 120) {
      setSliderCounter(3);
    } else if (secondCount >= 120 && secondCount < 150) {
      setSliderCounter(4);
    } else if (secondCount >= 150 && secondCount < 180) {
      setSliderCounter(5);
    }
  }, [secondCount]);

  const next = () => {
    setSecondCount(secondCount + 30);
  };

  const previous = () => {
    setSecondCount(secondCount - 30);
  };

  useEffect(() => {
    if (!isPlayMode) {
      setIsPlaying(false);
    }
  }, [isPlayMode]);

  const [newName, setNewName] = useState("");

  const [newMessage, setNewMessage] = useState("");

  const [botMessages, setBotMessages] = useState([
    "Hallo Jack, schön, Dich zu sehen. Was hast Du auf dem Herzen?",
    "Oh, das war ein wunderbares Fest. Wir hatten viele Freunde eingeladen in das Haus meiner Eltern in Diekholzen... War sehr lustig. Und es gibt viele Fotos dazu.",
    "Am nächsten Tag war die kirchliche Trauung. Unser Pastor hieß Meier Roscher. Auch dazu gibt es schöne Fotos. Dann fuhren wir nach Hannover (und) haben alle sehr schön gegessen.",
    "Und am nächsten Tag fuhren Uschi und ich mit dem Buggy für eine Woche nach Arosa. Ich lag allerdings drei Tage davon im Bett, weil ich mich erkältet hatte, denn der Buggy hatte keine Heizung. Aber die Hochzeit war schön.",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
    "Machs gut! Bis zum nächsten Mal. Tschüss!",
  ]);

  const [templateVideos, setTemplateVideos] = useState([
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-1.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-2.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-3.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-4.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
    "https://www.servermeternity.servist.de/api/images/placeholder/klaus-demo-5.mp4",
  ]);

  /*
  const sendMessage = () => {
    let messagesCopy = [];
    myMessages.map((message) => {
      messagesCopy.push(message);
    });
    messagesCopy.push(newMessage);
    setNewMessage("");
    setMyMessages(messagesCopy);
  };
  */

  // Send message to the backend
  const sendMessage = async () => {
    if (!newMessage.trim()) return;
    let message = newMessage;
    setNewMessage("");

    /* hier gtp abfrage
    const response = await fetch(`${apiURL}/api/chats/chatTest.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        id,
        message: message,
        instructions: initialInstructions,
      }),
    });
    await response.json().then((value) => storeToBotMessages(value));
*/
    setMyMessages([...myMessages, message]);
  };

  const storeToBotMessages = (value) => {
    let helpobject = value;
    let helpArr = botMessages;
    helpArr.push(helpobject.reply);
    setBotMessages(helpArr);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const handleKeyPressName = (event) => {
    if (event.key === "Enter") {
      setLocalCompanyData({
        ...localCompanyData, // Spread the existing state
        firstname: newName,
        lastModified: getTodayAsUnixDate(),
      });
    }
  };

  const changePlaymode = (onOff) => {
    if (onOff == "on") {
      setIsPlayMode(true);
      if (videoRef.current) {
      }
    } else {
      setIsPlayMode(false);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  useEffect(() => {
    // Ensure the scrolling is done after the DOM is updated
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth", // Optional: smooth scrolling
    });
  }, [localCompanyData, botMessages, myMessages, newMessage]);

  function getFileType(filename) {
    if (isEmpty(filename)) {
      return;
    }
    // Define the file extensions for each type
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".tiff",
      ".svg",
    ];
    const videoExtensions = [
      ".mp4",
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".mkv",
      ".webm",
    ];
    const audioExtensions = [".mp3", ".wav", ".ogg", ".flac", ".aac", ".m4a"];

    // Convert filename to lowercase to make the check case-insensitive
    const lowerCaseFilename = filename.toLowerCase();

    // Check the file extension
    if (imageExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "image";
    } else if (videoExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "video";
    } else if (audioExtensions.some((ext) => lowerCaseFilename.endsWith(ext))) {
      return "audio";
    } else {
      return "unknown"; // if the file type is not recognized
    }
  }

  return (
    <>
      {isMobile ? (
        <div className="home_mobile"></div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            authToken={authToken}
            destroySession={destroySession}
          />
          <div className="home_split" onClick={() => setSidebarOpen(false)}>
            <>
              {location == "/meet/" + id.toString() && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">
                      Hallo, ich bin{" "}
                      {!isEmpty(activeCompany) && activeCompany.firstname}
                    </div>
                    {/*
                              <div className="home_split_left_text">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet.
                    </div>
                    */}

                    <div className="home_split_left_form">
                      <div className="home_split_left_form_profile">
                        <div className="home_split_left_form_profile_col">
                          Zeig mir dein Leben.
                          <Togglebutton
                            active={false}
                            text={"Entdecken"}
                            clickEvent={clickOnExplore}
                          />
                        </div>
                        <div className="home_split_left_form_profile_col">
                          {" "}
                          Sprich mit mir.
                          <Togglebutton
                            active={false}
                            text={"Sprechen"}
                            clickEvent={clickOnTalk}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="home_split_right">
                    <img
                      src={
                        !isEmpty(activeCompany) &&
                        !isEmpty(activeCompany.photosFace) &&
                        JSON.parse(activeCompany.photosFace)[0]
                      }
                      className="home_split_right_backgroundFull"
                    />
                  </div>
                </>
              )}
              {location == "/meet/" + id.toString() + "/explore" && (
                <>
                  <div className="explore">
                    <div className="explore_title">
                      Ich bin{" "}
                      {!isEmpty(activeCompany) && activeCompany.firstname} und
                      das ist mein Leben
                    </div>
                    <div className="explore_subtitle">
                      Du kannst einzelne Episoden auswählen oder einfach alles
                      ansehen
                    </div>
                    <div className="explore_button">
                      <Togglebutton
                        active={false}
                        text={"Alles ansehen"}
                        clickEvent={clickOnExploreSlideshow}
                      />
                    </div>

                    <div className="explore_bottom">
                      <div className="explore_bottom_top">
                        {exploreFiles.map((file, index) => {
                          return (
                            <>
                              <div
                                className="explore_bottom_top_placeholder"
                                style={{
                                  width: 100 / exploreFiles.length / 2 + "vw",
                                }}></div>

                              <div
                                className={"explore_bottom_top_element"}
                                onClick={() => openSlideShow(index)}>
                                <div
                                  className="explore_bottom_top_element_top"
                                  style={{
                                    width: 100 / exploreFiles.length / 2 + "vw",
                                  }}>
                                  {file.title}
                                </div>
                                <div className="explore_bottom_top_element_middle">
                                  <div className="explore_bottom_top_element_middle_around"></div>
                                </div>
                                <div
                                  className={
                                    "explore_bottom_top_element_bottom" +
                                    (index % 3)
                                  }></div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="explore_bottom_middle">
                        <div className="explore_bottom_middle_line"></div>{" "}
                        <img
                          src="/assets/icn_arrowright.svg"
                          className="explore_bottom_middle_icon"
                        />
                      </div>
                      <div className="explore_bottom_bottom"></div>
                    </div>
                  </div>
                </>
              )}

              {location == "/meet/" + id.toString() + "/slideshow" && (
                <>
                  <div className="explore">
                    {isPlayMode ? (
                      <div className="explore_background">
                        {!isEmpty(exploreFiles[sliderCount]) &&
                        getFileType(exploreFiles[sliderCount].url) ==
                          "image" ? (
                          <img
                            src={
                              !isEmpty(exploreFiles[sliderCount])
                                ? exploreFiles[sliderCount].url
                                : ""
                            }
                            className="explore_background_img"
                          />
                        ) : (
                          <>
                            {!isEmpty(exploreFiles[sliderCount]) && (
                              <video
                                key={
                                  !isEmpty(exploreFiles[sliderCount])
                                    ? exploreFiles[sliderCount].url
                                    : ""
                                } // Add key prop here
                                controls={false}
                                className="explore_background_img"
                                preload="metadata"
                                id={
                                  !isEmpty(exploreFiles[sliderCount])
                                    ? exploreFiles[sliderCount].url
                                    : ""
                                }
                                autoPlay={true}
                                playsInline={true}
                                muted={false}
                                loop={true}>
                                <source
                                  key={exploreFiles[sliderCount].url + "src"}
                                  src={
                                    !isEmpty(exploreFiles[sliderCount])
                                      ? exploreFiles[sliderCount].url
                                      : ""
                                  }
                                  type={`video/mp4`}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div className="explore_background">
                        <div className="explore_background_list">
                          {exploreFiles.map((file) => {
                            return (
                              <div
                                className="explore_background_list_element"
                                onClick={() => openSlideShow(file.id - 1)}>
                                <div className="explore_background_list_element_top">
                                  <img
                                    src={file.url}
                                    className="explore_background_list_element_top_img"
                                  />
                                </div>
                                <div className="explore_background_list_element_bottom">
                                  {file.title}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div className="explore_bottom">
                      <div className="explore_bottom_top">
                        {exploreFiles.map((file, index) => {
                          return (
                            <>
                              <div
                                className="explore_bottom_top_placeholder"
                                style={{
                                  width: 100 / exploreFiles.length / 2 + "vw",
                                }}></div>
                              <div
                                className={"explore_bottom_top_element"}
                                onClick={() => openSlideShow(file.id - 1)}>
                                <div
                                  className="explore_bottom_top_element_top"
                                  style={{
                                    width: 100 / exploreFiles.length / 2 + "vw",
                                  }}>
                                  {file.title}
                                </div>
                                {sliderCount == index ? (
                                  <div className="explore_bottom_top_element_middle_active">
                                    <div className="explore_bottom_top_element_middle_around_active"></div>
                                  </div>
                                ) : (
                                  <div className="explore_bottom_top_element_middle">
                                    <div className="explore_bottom_top_element_middle_around"></div>
                                  </div>
                                )}

                                <div
                                  className={
                                    location !=
                                    "/meet/" + id.toString() + "/slideshow"
                                      ? "explore_bottom_top_element_bottom" +
                                        (index % 3)
                                      : "explore_bottom_top_element_bottomnone"
                                  }></div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className="explore_bottom_middle">
                        <div className="explore_bottom_middle_line"></div>
                        <div
                          className="explore_bottom_middle_line_active"
                          style={{
                            width:
                              `calc(${calculateWidth()}% + ` +
                              100 / exploreFiles.length / 2 +
                              `vw)`,
                            transition: "width 1s linear",
                          }}></div>
                        <img
                          src="/assets/icn_arrowright.svg"
                          className="explore_bottom_middle_icon"
                        />
                      </div>
                      <div className="explore_bottom_bottom">
                        <div className="explore_bottom_bottom_mediabar">
                          <div className="explore_bottom_bottom_mediabar_count">
                            {formatTime(secondCount)} | {formatTime(limit)}
                          </div>
                          <div className="explore_bottom_bottom_mediabar_mode">
                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() => changePlaymode("on")}>
                              <img
                                src="/assets/icn_film.svg"
                                className={
                                  isPlayMode
                                    ? "explore_bottom_bottom_mediabar_mode_iconbox_icon"
                                    : "explore_bottom_bottom_mediabar_mode_iconbox_icon inactive"
                                }
                              />
                            </div>
                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() => changePlaymode("off")}>
                              <img
                                src="/assets/icn_gallery.svg"
                                className={
                                  isPlayMode
                                    ? "explore_bottom_bottom_mediabar_mode_iconbox_icon inactive"
                                    : "explore_bottom_bottom_mediabar_mode_iconbox_icon"
                                }
                              />
                            </div>
                          </div>
                          <div className="explore_bottom_bottom_mediabar_play">
                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() => previous()}>
                              <img
                                src="/assets/icn_media-control-back.svg"
                                className="explore_bottom_bottom_mediabar_mode_iconbox_icon"
                              />
                            </div>
                            {isPlaying ? (
                              <div
                                className="explore_bottom_bottom_mediabar_mode_iconbox"
                                onClick={() => toggleIsPlaying()}>
                                <img
                                  src="/assets/icn_media-control-pause.svg"
                                  className="explore_bottom_bottom_mediabar_mode_iconbox_icon"
                                />
                              </div>
                            ) : (
                              <div
                                className="explore_bottom_bottom_mediabar_mode_iconbox"
                                onClick={() => toggleIsPlaying()}>
                                <img
                                  src="/assets/icn_media-control-play.svg"
                                  className="explore_bottom_bottom_mediabar_mode_iconbox_icon"
                                />
                              </div>
                            )}

                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() => next()}>
                              <img
                                src="/assets/icn_media-control-forward.svg"
                                className="explore_bottom_bottom_mediabar_mode_iconbox_icon"
                              />
                            </div>
                          </div>
                          <div className="explore_bottom_bottom_mediabar_sound">
                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() =>
                                isMuted ? setIsMuted(false) : setIsMuted(true)
                              }>
                              <img
                                src={
                                  isMuted
                                    ? "/assets/icn_media-control-volumeoff.svg"
                                    : "/assets/icn_media-control-volume.svg"
                                }
                                className="explore_bottom_bottom_mediabar_mode_iconbox_icon"
                              />
                            </div>
                            <div
                              className="explore_bottom_bottom_mediabar_mode_iconbox"
                              onClick={() =>
                                showVideo ? turnOffVideo() : turnOnVideo()
                              }>
                              <img
                                src={"/assets/icn_media-control-person.svg"}
                                className={
                                  showVideo
                                    ? "explore_bottom_bottom_mediabar_mode_iconbox_icon"
                                    : "explore_bottom_bottom_mediabar_mode_iconbox_icon inactive"
                                }
                              />
                            </div>
                          </div>

                          <div className="explore_bottom_bottom_mediabar_videobox">
                            {!isEmpty(exploreFiles[sliderCount]?.video) && (
                              <video
                                src={exploreFiles[sliderCount]?.video}
                                autoPlay={isPlaying}
                                muted={isMuted}
                                playsInline={true}
                                ref={videoRef}
                                className={
                                  showVideo && isPlayMode
                                    ? "explore_bottom_bottom_mediabar_videobox_video"
                                    : "d-none"
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {location == "/meet/" + id.toString() + "/talk" && (
                <>
                  <div className="home_split_left">
                    <div className="home_split_left_title">
                      HALLO,
                      <div className="home_split_left_title_inputbox">
                        {localCompanyData.firstname == "" ? (
                          <>
                            <input
                              type="text"
                              className="home_split_left_title_inputbox_input"
                              placeholder="Name eingeben"
                              onChange={(e) => setNewName(e.target.value)}
                              onKeyPress={(e) => handleKeyPressName(e)}
                              value={newName}
                            />
                            <div
                              className="home_split_left_title_inputbox_enter"
                              onClick={() =>
                                setLocalCompanyData({
                                  ...localCompanyData, // Spread the existing state
                                  firstname: newName, // Update only the firstname
                                  lastModified: getTodayAsUnixDate(),
                                })
                              }>
                              <img
                                src="/assets/icn_arrowrightblack.svg"
                                className="home_split_left_title_inputbox_enter_icon"
                              />
                            </div>
                          </>
                        ) : (
                          <div className="home_split_left_title_inputbox_filled">
                            {localCompanyData.firstname}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="home_split_left_text">
                      Frage mich etwas zu meinen Erlebnissen oder meinen Werten
                      und Lebenserfahrungen.
                    </div>
                    <div className="home_split_left_chat">
                      <div className="home_split_left_chat_chatboxavatar">
                        <div className="home_split_left_chat_chatboxavatar_middle">
                          <div className="home_split_left_chat_chatboxavatar_middle_top">
                            {!isEmpty(activeCompany) && activeCompany.firstname}
                          </div>
                          <div className="home_split_left_chat_chatboxavatar_middle_bottom">
                            {isEmpty(botMessages[0]) ? (
                              <Spinner type="white" />
                            ) : (
                              botMessages[0]
                            )}
                          </div>
                        </div>
                        <div className="home_split_left_chat_chatboxavatar_left">
                          <img
                            src={
                              !isEmpty(activeCompany) &&
                              !isEmpty(activeCompany.photosFace) &&
                              JSON.parse(activeCompany.photosFace)[0]
                            }
                            className="home_split_left_chat_chatboxme_left_icon"
                          />
                        </div>
                      </div>
                      {myMessages.map((message, index) => (
                        <>
                          <div
                            className="home_split_left_chat_chatboxme"
                            key={message + index}>
                            <div className="home_split_left_chat_chatboxme_left">
                              <img
                                src="/assets/icn_userdefault.svg"
                                className="home_split_left_chat_chatboxme_left_icon"
                              />
                            </div>
                            <div className="home_split_left_chat_chatboxme_middle">
                              <div className="home_split_left_chat_chatboxme_middle_top">
                                {localCompanyData.firstname == ""
                                  ? "Name"
                                  : localCompanyData.firstname}
                              </div>
                              <div className="home_split_left_chat_chatboxme_middle_bottom">
                                {message}
                              </div>
                            </div>
                            <div className="home_split_left_chat_chatboxme_right">
                              <div
                                className="home_split_left_chat_chatboxme_right_enter"
                                id={"enterbutton+myMessages.length"}></div>
                            </div>
                          </div>
                          <div className="home_split_left_chat_chatboxavatar">
                            <div className="home_split_left_chat_chatboxavatar_middle">
                              <div className="home_split_left_chat_chatboxavatar_middle_top">
                                {!isEmpty(activeCompany) &&
                                  activeCompany.firstname}
                              </div>
                              <div className="home_split_left_chat_chatboxavatar_middle_bottom">
                                {isEmpty(botMessages[index + 1]) ? (
                                  <Spinner type="white" />
                                ) : (
                                  botMessages[index + 1]
                                )}
                              </div>
                            </div>
                            <div className="home_split_left_chat_chatboxavatar_left">
                              <img
                                src={
                                  !isEmpty(activeCompany) &&
                                  !isEmpty(activeCompany.photosFace) &&
                                  JSON.parse(activeCompany.photosFace)[0]
                                }
                                className="home_split_left_chat_chatboxme_left_icon"
                              />
                            </div>
                          </div>
                        </>
                      ))}

                      <div className="home_split_left_chat_chatboxme">
                        <div className="home_split_left_chat_chatboxme_left">
                          <img
                            src="/assets/icn_userdefault.svg"
                            className="home_split_left_chat_chatboxme_left_icon"
                          />
                        </div>
                        <div className="home_split_left_chat_chatboxme_middle">
                          <div className="home_split_left_chat_chatboxme_middle_top">
                            {localCompanyData.firstname == ""
                              ? "Name"
                              : localCompanyData.firstname}
                          </div>
                          <div className="home_split_left_chat_chatboxme_middle_bottom">
                            <input
                              type="text"
                              className="home_split_left_chat_chatboxme_middle_bottom_input"
                              placeholder="Frage eingeben"
                              value={newMessage}
                              onChange={(e) => setNewMessage(e.target.value)}
                              onKeyPress={(e) => handleKeyPress(e)}
                            />
                            <div
                              className="home_split_left_title_inputbox_enter"
                              onClick={() => sendMessage()}>
                              <img
                                src="/assets/icn_arrowrightblack.svg"
                                className="home_split_left_title_inputbox_enter_icon"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="home_split_left_chat_chatboxme_right"></div>
                      </div>
                    </div>
                  </div>
                  <div className="home_split_right">
                    <video
                      key={
                        !isEmpty(templateVideos[myMessages.length])
                          ? templateVideos[myMessages.length]
                          : ""
                      } // Add key prop here
                      controls={false}
                      className="home_split_right_backgroundFull"
                      preload="metadata"
                      id={
                        !isEmpty(templateVideos[myMessages.length])
                          ? templateVideos[myMessages.length]
                          : ""
                      }
                      autoPlay={true}
                      playsInline={true}
                      muted={false}
                      loop={false}>
                      <source
                        key={templateVideos[myMessages.length] + "src"}
                        src={
                          !isEmpty(templateVideos[myMessages.length])
                            ? templateVideos[myMessages.length]
                            : ""
                        }
                        type={`video/mp4`}
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseButton = styled(MdClose)`
  font-size: 15px;
  color: #ffffff;
`;

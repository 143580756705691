import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowBack,
  MdArrowBackIos,
  MdBusiness,
  MdBusinessCenter,
  MdCampaign,
  MdChildCare,
  MdFavoriteBorder,
  MdFitnessCenter,
  MdLanguage,
  MdLightMode,
  MdMenu,
  MdMonetizationOn,
  MdOutlineCampaign,
  MdOutlineMonetizationOn,
  MdOutlineShoppingCart,
  MdPersonOutline,
  MdPets,
  MdPlayCircleFilled,
  MdPlayCircleOutline,
  MdRestaurant,
  MdSearch,
  MdShield,
  MdShoppingCart,
  MdSports,
  MdSportsMma,
} from "react-icons/md";
import "../styles/Home.css";
import "../styles/Dropdown.css";
import { useLocation, useNavigate } from "react-router";
import {
  FaBlog,
  FaDollarSign,
  FaGamepad,
  FaLanguage,
  FaTshirt,
} from "react-icons/fa";
import { FaPerson, FaSnapchat } from "react-icons/fa6";
import { BsStars } from "react-icons/bs";

import { useLanguage } from "../contexts/LanguageContext";
import Togglebutton from "./Togglebutton";
import { getTodayAsUnixDate } from "../functions/getTodayAsUnixDate";

export default function Dropdown({
  isMobile,
  authToken,
  companyData,
  active,
  title,
  clickEvent,
  type,
  localCompanyData,
  setLocalCompanyData,
  password,
  setPassword,
}) {
  let navigate = useNavigate();
  const { language, switchLanguage } = useLanguage();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (type == "avatargenerieren") {
      setOpen(true);
    }
    if (title == "Soziodemografie") {
      setOpen(true);
    }
    if (title == "Hauptberuf") {
      setOpen(true);
    }
    if (title == "Persönliche Daten" || title == "Login Daten") {
      setOpen(true);
    }
  }, [type]);

  const clickOnFaceScan = () => {
    navigate("/create/gesichtscannen/vonvorne");
  };

  const clickOnVoiceScan = () => {
    navigate("/create/stimmeaufnehmen");
  };

  const clickOnAvatarChange = () => {
    navigate("/create/characterdefinieren");
  };

  const clickOnQuestions = () => {
    navigate("/create/characterdefinieren");
  };

  const clickOnOtherProfiles = () => {};

  const clickOnMedia = () => {
    navigate("/create/lebenkuratieren");
  };

  const clickOnHistory = () => {
    //  navigate("/create/geschichteerzaehlen");
  };

  const clickOnTimeline = () => {
    navigate("/create/zeitablaufbearbeiten");
  };

  const changeJobs = (value) => {
    let copy = { ...localCompanyData };
    let helpArray = [];
    if (copy.jobs.includes(value)) {
      console.log(copy);

      copy.jobs.map((cop) => {
        if (cop != value) {
          helpArray.push(cop);
        }
      });
    } else {
      console.log(copy);

      copy.jobs.map((cop) => {
        helpArray.push(cop);
      });
      helpArray.push(value);
    }

    copy.jobs = helpArray;
    copy.lastModified = getTodayAsUnixDate();
    setLocalCompanyData(copy);
  };

  const berufeListe = [
    "Angestellter",
    "Arbeiter",
    "Führungsrolle",
    "Chef",
    "Unternehmer",
    "Politiker",
    "Journalist",
    "Medienschaffender",
    "Handwerker",
    "Jurist",
    "Kaufmann",
    "Pädagoge",
    "Mediziner",
    "Soldat",
    "in Schiffahrt tätig",
    "in Landwirtschaft tätig",
    "Ingenieur",
    "IT-Spezialist",
    "in Marketing tätig",
    "Logistiker",
    "in Transport/Verkehr tätig",
  ];

  const isStringInArray = (str, arr) => {
    return arr.includes(str);
  };

  return (
    <>
      <div className="dropdown">
        {type != "avatargenerieren" && (
          <div className="dropdown_title" onClick={() => setOpen(!open)}>
            <div className="dropdown_title_left">{title}</div>
            <div className="dropdown_title_right">
              {open ? (
                <img
                  src="/assets/icn_arrowup.svg"
                  className="dropdown_title_right_icon"
                />
              ) : (
                <img
                  src="/assets/icn_arrowdown.svg"
                  className="dropdown_title_right_icon"
                />
              )}
            </div>
          </div>
        )}

        {type == "avatargenerieren" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"Gesicht scannen"}
                      clickEvent={clickOnFaceScan}
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"Stimme aufnehmen"}
                      clickEvent={clickOnVoiceScan}
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"Avatar bearbeiten"}
                      clickEvent={clickOnAvatarChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {type == "charakterdefine" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_left">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </div>
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"FRAGEN BEANTWORTEN"}
                      clickEvent={clickOnQuestions}
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_left">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </div>
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"ANDERE PROFILE FREISCHALTEN"}
                      clickEvent={clickOnOtherProfiles}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {type == "lebenkuratieren" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_left">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </div>
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"MEDIEN HOCHLADEN UND BEARBEITEN"}
                      clickEvent={clickOnMedia}
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_left">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </div>
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"GESCHICHTE ERZÄHLEN"}
                      clickEvent={clickOnHistory}
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_radiobox">
                  <div className="dropdown_body_row_radiobox_left">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et
                  </div>
                  <div className="dropdown_body_row_radiobox_right">
                    <Togglebutton
                      active={false}
                      text={"ZEITABLAUF BEARBEITEN"}
                      clickEvent={clickOnTimeline}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {title == "Soziodemografie" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_genderbuttons">
                  <div
                    className="dropdown_body_row_genderbuttons_button"
                    onClick={() =>
                      setLocalCompanyData({
                        ...localCompanyData, // Spread the existing state
                        gender: "male", // Update only the firstname
                        lastModified: getTodayAsUnixDate(),
                      })
                    }>
                    {localCompanyData.gender == "male" ? (
                      <div className="dropdown_body_row_genderbuttons_button_circle_active">
                        <div className="dropdown_body_row_genderbuttons_button_circle_active_innercircle"></div>
                      </div>
                    ) : (
                      <div className="dropdown_body_row_genderbuttons_button_circle"></div>
                    )}
                    männlich
                  </div>
                  <div
                    className="dropdown_body_row_genderbuttons_button"
                    onClick={() =>
                      setLocalCompanyData({
                        ...localCompanyData, // Spread the existing state
                        gender: "female", // Update only the firstname
                        lastModified: getTodayAsUnixDate(),
                      })
                    }>
                    {localCompanyData.gender == "female" ? (
                      <div className="dropdown_body_row_genderbuttons_button_circle_active">
                        <div className="dropdown_body_row_genderbuttons_button_circle_active_innercircle"></div>
                      </div>
                    ) : (
                      <div className="dropdown_body_row_genderbuttons_button_circle"></div>
                    )}
                    weiblich
                  </div>
                  <div
                    className="dropdown_body_row_genderbuttons_button"
                    onClick={() =>
                      setLocalCompanyData({
                        ...localCompanyData, // Spread the existing state
                        gender: "other", // Update only the firstname
                        lastModified: getTodayAsUnixDate(),
                      })
                    }>
                    {localCompanyData.gender == "other" ? (
                      <div className="dropdown_body_row_genderbuttons_button_circle_active">
                        <div className="dropdown_body_row_genderbuttons_button_circle_active_innercircle"></div>
                      </div>
                    ) : (
                      <div className="dropdown_body_row_genderbuttons_button_circle"></div>
                    )}
                    divers
                  </div>
                </div>
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    Geburtsort
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.birthplace}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData, // Spread the existing state
                          birthplace: e.target.value, // Update only the firstname
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <div className="dropdown_body_row_inputs_box">
                    aktueller Wohnort
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.adress}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData, // Spread the existing state
                          adress: e.target.value, // Update only the firstname
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    weiterer Wohnort
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.adressAlt}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData, // Spread the existing state
                          adressAlt: e.target.value, // Update only the firstname
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <div className="dropdown_body_row_inputs_box">
                    in welchem Zeitraum
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.timeOnAdress}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData, // Spread the existing state
                          timeOnAdress: e.target.value, // Update only the firstname
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <img
                    className="dropdown_body_row_inputs_addButton"
                    src="/assets/icn_add.svg"
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {title == "Hauptberuf" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_toptext">
                  Mehrfachauswahl möglich
                </div>
                <div className="dropdown_body_row_bottombuttons">
                  {berufeListe.map((beruf) => (
                    <Togglebutton
                      active={
                        isStringInArray(beruf, localCompanyData.jobs)
                          ? true
                          : false
                      }
                      text={beruf}
                      clickEvent={() => changeJobs(beruf)}
                      type="small"
                    />
                  ))}
                </div>
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    Sonstiges
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                    />
                  </div>

                  <img
                    className="dropdown_body_row_inputs_addButton"
                    src="/assets/icn_add.svg"
                  />
                  <div className="dropdown_body_row_inputs_addButtonWithBorder">
                    <img
                      className="dropdown_body_row_inputs_addButtonWithBorder_btn"
                      src="/assets/icn_audio.svg"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {title == "Persönliche Daten" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    Name
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.lastname}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData,
                          lastname: e.target.value,
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <div className="dropdown_body_row_inputs_box">
                    Vorname
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.firstname}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData,
                          firstname: e.target.value,
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    Geburtsdatum
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.birthdate}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData,
                          birthdate: e.target.value,
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <div className="dropdown_body_row_inputs_box">
                    E-Mail
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.email}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData,
                          email: e.target.value,
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    Passwort
                    <input
                      type="password"
                      className="dropdown_body_row_inputs_box_input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {title == "Login Daten" && (
          <div className="dropdown_body">
            {open && (
              <div className="dropdown_body_row">
                <div className="dropdown_body_row_inputs">
                  <div className="dropdown_body_row_inputs_box">
                    E-Mail
                    <input
                      type="text"
                      className="dropdown_body_row_inputs_box_input"
                      value={localCompanyData.email}
                      onChange={(e) =>
                        setLocalCompanyData({
                          ...localCompanyData,
                          email: e.target.value,
                          lastModified: getTodayAsUnixDate(),
                        })
                      }
                    />
                  </div>
                  <div className="dropdown_body_row_inputs_box">
                    Passwort
                    <input
                      type="password"
                      className="dropdown_body_row_inputs_box_input"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

const ArrowBack = styled(MdArrowBack)`
  font-size: 22px;
  color: #575757;
  margin-top: 1px;
  margin-right: 5px;
`;

import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { MdArrowBack, MdArrowBackIos, MdMenu } from "react-icons/md";

import "../styles/Login.css";
import { useNavigate } from "react-router";
import { isEmpty } from "../functions/isEmpty";
import Spinner from "../components/Spinner";
import DesktopTopNavbar from "../components/DesktopTopNavbar";
import Dropdown from "../components/Dropdown";
import Togglebutton from "../components/Togglebutton";

export default function Login({
  authToken,
  email,
  setEmail,
  password,
  setPassword,
  signIn,
  signUp,
  username,
  setUsername,
  creatorData,
  shoppingCard,
  topnavBusiness,
  setTopnavBusiness,
  isUploading,
  setIsUploading,
  companyData,
  isMobile,
  sidebarOpen,
  setSidebarOpen,
  localCompanyData,
  setLocalCompanyData,
  destroySession,
}) {
  const [page, setPage] = useState("register");
  const [formIsFilled, setFormIsFilled] = useState(false);

  let navigate = useNavigate();

  // Attach an event listener when the component mounts
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is the Enter key (key code 13)
      if (event.keyCode === 13 && formIsFilled && page == "register") {
        // Call the function when Enter key is pressed
        setIsUploading(true);
        signUp();
      } else if (event.keyCode === 13 && formIsFilled && page != "register") {
        setIsUploading(true);
        signIn();
      }
    };

    // Add event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [formIsFilled, page, username, email, password]);

  // userdata in state packen
  useEffect(() => {
    if (authToken) {
      navigate("/");
    }
  }, [authToken, shoppingCard, creatorData]);

  useEffect(() => {
    if (
      (page == "register" && isEmpty(username)) ||
      isEmpty(email) ||
      isEmpty(password) ||
      !validateEmail(email) ||
      password.length < 6
    ) {
      setFormIsFilled(false);
    } else {
      setFormIsFilled(true);
    }
  }, [username, email, password, page]);

  const signUpAndDisableButton = () => {
    setIsUploading(true);
    signUp();
  };

  const signInAndDisableButton = () => {
    setIsUploading(true);
    signIn();
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <>
      {isMobile ? (
        <div className="home_mobile"></div>
      ) : (
        <div className="home">
          <DesktopTopNavbar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            authToken={authToken}
            destroySession={destroySession}
          />
          <div className="home_split" onClick={() => setSidebarOpen(false)}>
            <div className="home_split_left">
              <div className="home_split_left_title">
                {page == "register" ? (
                  <>
                    <div className="home_split_left_title_left_active">
                      Profil erstellen
                    </div>
                    <div
                      className="home_split_left_title_right"
                      onClick={() => setPage("login")}>
                      Einloggen
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="home_split_left_title_left"
                      onClick={() => setPage("register")}>
                      Profil erstellen
                    </div>
                    <div className="home_split_left_title_right_active">
                      Einloggen
                    </div>
                  </>
                )}
              </div>
              {/*
        <div className="home_split_left_text"></div>
        */}
              {page == "register" ? (
                <>
                  <Dropdown
                    title={"Persönliche Daten"}
                    active={true}
                    localCompanyData={localCompanyData}
                    setLocalCompanyData={setLocalCompanyData}
                    password={password}
                    setPassword={setPassword}
                  />
                  <Dropdown title={"Abomodell/Zahlungsdaten"} />
                  <Dropdown title={"Zugriffsberechtigungen"} />
                  <Dropdown title={"Und so weiter..."} />
                </>
              ) : (
                <>
                  <Dropdown
                    title={"Login Daten"}
                    active={true}
                    localCompanyData={localCompanyData}
                    setLocalCompanyData={setLocalCompanyData}
                    password={password}
                    setPassword={setPassword}
                  />
                </>
              )}

              <div className="home_split_left_buttons">
                {page == "register" ? (
                  <Togglebutton
                    active={false}
                    text={"Profil erstellen"}
                    clickEvent={signUpAndDisableButton}
                  />
                ) : (
                  <Togglebutton
                    active={false}
                    text={"Einloggen"}
                    clickEvent={signInAndDisableButton}
                  />
                )}
              </div>
            </div>
            <div className="home_split_right">
              <img
                src="https://www.developercloud.letsfluence.com/api/images/placeholder/peter_large.jpg"
                className="home_split_right_backgroundFull"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 33px;
  color: #575757;
`;

const ArrowBack = styled(MdArrowBack)`
  font-size: 20px;
  color: #636363;
  margin-top: 2px;
`;

const SearchIcon = styled(MdArrowBackIos)`
  font-size: 20px;
  color: #ffffff;
  transform: rotate(-90deg);
  margin-top: -8px;
`;
